@import "../../../node_modules/@hlk/lobster/lobster-global-theme/variables";
@import "../../../node_modules/@hlk/lobster/lobster-global-theme/mixins";

.page_layout {
  min-height: 100vh;
  @include lt-md {
    padding-top: 93px;
  }
}

.page_layout-content {
  position: relative;
  padding-bottom: 120px;

  @include gt-md {
    flex: 1 1 100%;
    max-height: 100vh;
    overflow: auto;
  }
}
